import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Styles from './Carousel.module.css';

const Dots = memo(({ isActive }) => {
  return (
    <div
      className={`${Styles.dots}  ${isActive ? '' : Styles.notVisited}`}
      data-testid={isActive}
    />
  );
});

Dots.propTypes = {
  isActive: PropTypes.bool.isRequired
};

export default Dots;
