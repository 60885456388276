import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './Carousel.module.css';
import Dots from './Dots';
import Icon from '../Icon';

const CarouselControl = memo(
  ({ imageTextArray, currentPosition, onMoveForward }) => {
    return (
      <div className={styles.stripeAndButtonContainer}>
        <div className={styles.changeImageContainer}>
          <button
            type="button"
            onClick={onMoveForward}
            data-testid="button-ChangeIndex"
            className={styles.changeSlideButton}>
            <Icon name="chevron-right" size="2x" />
          </button>
          {imageTextArray.map((data, i) => {
            return (
              <Dots
                isActive={i === currentPosition}
                key={data.headerText}
                data-testid="dots"
              />
            );
          })}
        </div>
        <div className={styles.pinkStripe} />
      </div>
    );
  }
);

CarouselControl.propTypes = {
  imageTextArray: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      imageUrl: PropTypes.string
    })
  ).isRequired,
  currentPosition: PropTypes.number.isRequired,
  onMoveForward: PropTypes.func.isRequired
};
export default CarouselControl;
