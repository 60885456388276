import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Image from '../Image';
import styles from './Carousel.module.css';
import Descriptor from '../Descriptor/index';

const CarouselDisplay = memo(({ imageTextArray, currentPosition }) => {
  return (
    <div className={styles.textAndImage}>
      <div className={styles.overlay} />
      <div className={styles.textContainer}>
        <Descriptor
          title={imageTextArray[currentPosition].headerText}
          description={imageTextArray[currentPosition].subText}
          titleStyle={styles.headerText}
          descriptorAlignment={styles.descriptorAlignment}
          descriptionStyle={styles.subText}
          descriptionInlineStyle={imageTextArray[currentPosition].subTextWidth}
          titleInlineStyle={imageTextArray[currentPosition].headerTextWidth}
        />
      </div>

      <div className={styles.imageContainer}>
        <Image
          webpImage={imageTextArray[currentPosition].webpUrl}
          src={imageTextArray[currentPosition].imageUrl}
          alt="Office image"
          className={styles.image}
          key={currentPosition}
        />
      </div>
    </div>
  );
});

CarouselDisplay.propTypes = {
  imageTextArray: PropTypes.arrayOf(
    PropTypes.shape({
      headerText: PropTypes.string,
      subText: PropTypes.string,
      webpUrl: PropTypes.string,
      imageUrl: PropTypes.string,
      subTextWidth: PropTypes.shape({
        maxWidth: PropTypes.string
      }),
      headerTextWidth: PropTypes.shape({
        maxWidth: PropTypes.string
      })
    })
  ).isRequired,
  currentPosition: PropTypes.number.isRequired
};
export default CarouselDisplay;
