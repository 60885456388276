import React from 'react';
import styles from './Vision.module.css';
import Carousel from '../../../Components/Carousel';

import ABEDUCATIONSpng from '../../../assets/Vision/ab-educations.png';
import SEMINARjpeg from '../../../assets/Vision/seminar-image.jpeg';
import SOCIANApng from '../../../assets/Vision/sociana.png';
import INDIASSUPERBRAINpng from '../../../assets/Vision/isb.png';

import ABEDUCATIONSwebp from '../../../assets/Vision/ab-educations.webp';
import SEMINARwebp from '../../../assets/Vision/seminar-image.webp';
import SOCIANAwebp from '../../../assets/Vision/sociana.webp';
import INDIASSUPERBRAINwebp from '../../../assets/Vision/isb.webp';

const textImageData = [
  {
    headerText: 'INNOVATIVE COURSES OFFERING GUARANTEED INTERNSHIPS',
    subText: 'Now with a Study Now Pay Later Option',
    imageUrl: ABEDUCATIONSpng,
    webpUrl: ABEDUCATIONSwebp,
    subTextWidth: {
      maxWidth: '30ch'
    },
    headerTextWidth: {
      maxWidth: '15ch'
    }
  },
  {
    headerText: 'EMPOWERING EVERYONE WITH EQUALITY',
    subText: 'We aim for a society thriving with equality and passion',
    imageUrl: SEMINARjpeg,
    webpUrl: SEMINARwebp,
    subTextWidth: {
      maxWidth: '30ch'
    },
    headerTextWidth: {
      maxWidth: '14ch'
    }
  },
  {
    headerText: 'FIGHT COVID, TAKE YOUR EVENTS ONLINE',
    subText:
      'Weddings | Conferences | Concerts | Expos | College Fests | Trade Fairs',
    imageUrl: SOCIANApng,
    webpUrl: SOCIANAwebp,
    subTextWidth: {
      maxWidth: '30ch'
    },
    headerTextWidth: {
      maxWidth: '15ch'
    }
  },
  {
    headerText: 'DREAM. DISCOVER. DEVELOP.',
    subText: `India's most unique competition ever "India's Super Brain"`,
    imageUrl: INDIASSUPERBRAINpng,
    webpUrl: INDIASSUPERBRAINwebp,
    subTextWidth: {
      maxWidth: '20ch'
    },
    headerTextWidth: {
      maxWidth: '15ch'
    }
  }
];
const Vision = () => {
  return (
    <div className={styles.container} id="home">
      <Carousel dataArray={textImageData} />
    </div>
  );
};
export default Vision;
