import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Carousel.module.css';
import CarouselDisplay from './CarouselDisplay';
import CarouselControl from './CarouselControls';

const maxTimeout = 3000;

const Carousel = ({ dataArray }) => {
  const [currentIndex, changecurrentIndex] = useState(0);

  // useEffect is called when ever currentIndex is being changed after fixed interval.
  useEffect(() => {
    if (!Array.isArray(dataArray) && dataArray.length === 0) {
      return;
    }
    const next = (currentIndex + 1) % dataArray.length;
    const id = setInterval(() => changecurrentIndex(next), maxTimeout);
    return () => clearInterval(id);
  }, [currentIndex, dataArray]);

  /* incrementcurrentIndex function is used to change the slider on click of button and useCallback is being called after change of currentIndex. */
  const incrementCurrentIndex = useCallback(() => {
    if (!Array.isArray(dataArray) && dataArray.length === 0) {
      return;
    }

    if (currentIndex === dataArray.length - 1) {
      changecurrentIndex(0);
    } else {
      changecurrentIndex(currentIndex + 1);
    }
  }, [currentIndex, dataArray]);

  return (
    <div className={styles.container} data-testid="carousel">
      {dataArray && Array.isArray(dataArray) && dataArray.length > 0 && (
        <>
          <CarouselDisplay
            imageTextArray={dataArray}
            currentPosition={currentIndex}
          />
          <CarouselControl
            imageTextArray={dataArray}
            currentPosition={currentIndex}
            onMoveForward={incrementCurrentIndex}
          />
        </>
      )}
    </div>
  );
};

Carousel.propTypes = {
  dataArray: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      imageUrl: PropTypes.string
    })
  ).isRequired
};

export default Carousel;
